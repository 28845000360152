.portal-header {
  background-color: var(--theme-background-header);
  border-bottom: 1px solid var(--theme-border);
  display: flex;
  align-items: center;
  padding: 0 40px;

  .uid2-logo {
    height: 30px;
  }

  .title {
    flex-grow: 1;
    padding: 20px 20px 15px 0;
  }

  .profile-dropdown-button {
    all: unset;
    font-weight: 600;
    border-bottom: 5px solid var(--theme-button);
    margin-right: 10px;
    svg {
      margin-left: 8px;
    }
  }

  .profile-dropdown-content {
    background-color: var(--theme-background-header);
    box-shadow: 0 2px 6px 1px rgba(0, 0, 0, 0.16);
    padding: 20px;
    border-radius: 5px;
    border: 1px solid var(--theme-border);
    display: flex;
    flex-direction: column;
    min-width: 200px;

    .profile-dropdown-arrow {
      fill: var(--theme-border);
    }

    .portal-avatar-container {
      display: flex;
      justify-content: right;
      .portal-avatar {
        margin: 5px;
        overflow: hidden;
        border-radius: 20px;
        height: 80px;
      }
    }

    .separator {
      height: 1px;
      background-color: var(--theme-border);
      margin: 10px -5px;
    }

    .dropdown-menu-item {
      cursor: pointer;
      display: flex;
      height: 40px;
      align-items: center;
      color: var(--theme-secondary);

      .link {
        display: flex;
        align-items: center;
        height: 100%;
        width: 100%;
        text-decoration: none;
      }
    }

    *:hover {
      outline: none;
    }
  }

  .theme-switch {
    button {
      all: unset;
    }

    color: var(--theme-secondary);
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    margin-bottom: 10px;

    .theme-toggle {
      width: 42px;
      height: 25px;
      background-color: gray;
      border-radius: 9999px;
      margin-left: 10px;
      cursor: pointer;
    }

    .theme-toggle[data-state='checked'] {
      background-color: black;
    }

    .thumb {
      display: block;
      width: 21px;
      height: 21px;
      background-color: white;
      border-radius: 9999px;
      transform: translateX(2px);
    }

    .thumb[data-state='checked'] {
      transform: translateX(19px);
    }
  }
}
