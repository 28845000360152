.user-management-item {
  line-height: 2;

  .user-item-name-cell {
    display: flex;
    align-items: center;
  }

  .approve-button {
    padding-right: 0;
  }

  .approver-date {
    margin-right: 80px;
  }

  .approver-name {
    margin-right: 40px;
  }

  .theme-switch {
    button {
      all: unset;
    }

    color: var(--theme-secondary);
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    margin-bottom: 10px;

    .theme-toggle {
      width: 42px;
      height: 25px;
      background-color: gray;
      border-radius: 9999px;
      margin-left: 10px;
      cursor: pointer;
    }

    .theme-toggle[data-state='checked'] {
      background-color: crimson;
    }

    .thumb {
      display: block;
      width: 21px;
      height: 21px;
      background-color: white;
      border-radius: 9999px;
      transform: translateX(2px);
    }

    .thumb[data-state='checked'] {
      transform: translateX(19px);
    }
  }

  .viewable-button {
    cursor: pointer;
    color: var(--theme-action);
    border: none;
    white-space: nowrap;
    align-items: center;
    padding-left: 0;
    padding-right: 10px;
  }
}
